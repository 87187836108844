'use client'
import React from 'react';

import { Container, Typography } from '@mui/material';
import { ComponentProps, DefaultPageProps } from '@/lib/types/shared/page.types';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import Image from '@/components/shared/Image';
import ButtonOne, { ButtonOneProps } from '@/components/shared/ButtonOne';
import HeadingText from '@/components/shared/HeadingText';
import { capitalize } from '@/lib/utils';

type Props = {
    title: string,
    subTitle?: string,
    certificateImages?: boolean,
    buttons?: ButtonOneProps[],
    children?: React.ReactNode,
    headerImage?: {
        src: string,
        alt: string,
    }
} & DefaultPageProps & ComponentProps;

const defaultHeaderImage = {
    src: 'team_mtbfhu',
    alt: 'Webbeukers B.V. team',
};

export default function HeroNew({ title = '', subTitle = '', className = '', buttons, children, params, certificateImages = true, headerImage = defaultHeaderImage }: Props) {

    return (
        <div className={`relative ${className}`}>
            <div className="hidden md:flex w-full absolute bottom-4 top-1/2 -translate-y-1/2 z-10 px-1/8 flex-col justify-center">
                {title !== undefined && title !== null && title !== '' &&
                    <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                        <HeadingText variant='h1' className='md:text-6xl lg:text-7xl md:leading-extra-tight'>
                            {capitalize(title)}
                        </HeadingText>
                    </MoveFadeInAnimation>
                }
                {subTitle !== undefined && subTitle !== null && subTitle !== '' &&
                    <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                        <HeadingText variant='h2' className='md:text-4xl lg:text-5xl mt-3 lg:mt-1'>
                            {subTitle}
                        </HeadingText>
                    </MoveFadeInAnimation>
                }
                {buttons && buttons.length > 0 && (
                    <div className="flex gap-5 mt-5">
                        {buttons.map((button, index) => (
                            <MoveFadeInAnimation key={index} delay={index * 200} direction="right" distance="xl" amount={0.5}>
                                {(button.onclick) ? (
                                    <ButtonOne variant={button.variant} text={button.text} type="button" onclick={button.onclick} hoverEffect={true} />
                                ) : (
                                    <ButtonOne {...button} key={index} hoverEffect={true}/>
                                )}
                            </MoveFadeInAnimation>
                          
                        ))}
                    </div>
                )}
                {children && children}
            </div>

            <Container>
                <div className='relative'>
                    <Image
                        width={1100}
                        height={619}
                        src={headerImage.src}
                        alt={headerImage.alt}
                        className='w-full h-72 sm:h-auto lg:aspect-16/7 object-cover rounded-3xl filter brightness-75 sm:filter-none hidden md:block'
                    />

                    <div className="flex md:hidden flex-col h-full w-full justify-center">
                        {title !== undefined && title !== null && title !== '' &&
                            <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant='h1' className='mb-6'>
                                    {capitalize(title)}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        }
                        {subTitle !== undefined && subTitle !== null && subTitle !== '' &&
                            <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant='h2' className='sm:mt-2'>
                                    {subTitle}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        }
                        <div className="flex flex-col gap-2 mb-6">
                            {children && children}
                        </div>
                        <div className='w-full h-fit relative'>
                            <Image
                                width={1100}
                                height={619}
                                src={headerImage.src}
                                alt={headerImage.alt}
                                className='w-full h-64 sm:h-auto object-cover rounded-3xl filter brightness-75 sm:filter-none'
                            />

                            {certificateImages && (
                                <div className="my-4 flex justify-start gap-4 absolute bottom-2 right-4">
                                    <Image
                                        loading='lazy'
                                        src="iso-27001_tfrdns"
                                        alt="ISO 27001"
                                        width={68.53}
                                        height={97.45}
                                        className="w-12 md:w-20 h-auto"
                                    />
                                    <Image
                                        loading='lazy'
                                        src="iso-9001_os04xg"
                                        alt="ISO 9001"
                                        width={68.53}
                                        height={97.45}
                                        className="w-12 md:w-20 h-auto"
                                    />
                                </div>
                            )}
                        </div>
                        {buttons && buttons.length > 0 && (
                            <div className="flex flex-col gap-2 mt-5">
                                {buttons.map((button, index) => (
                                    <MoveFadeInAnimation key={index} delay={index * 200} direction="right" distance="xl" amount={0.5}>
                                        <ButtonOne
                                            {...button}
                                            key={index}
                                            hoverEffect={true}
                                            className='text-base'
                                        />
                                    </MoveFadeInAnimation>
                                
                                ))}
                            </div>
                        )}
                    </div>

                    {certificateImages && (
                        <div className="hidden md:flex absolute bottom-0 right-0 my-4 mx-6 justify-center gap-4">
                            <Image
                                loading='lazy'
                                src="iso-27001_tfrdns"
                                alt="ISO 27001"
                                width={68.53}
                                height={97.45}
                                className="w-12 md:w-20 h-auto"
                            />
                            <Image
                                loading='lazy'
                                src="iso-9001_os04xg"
                                alt="ISO 9001"
                                width={68.53}
                                height={97.45}
                                className="w-12 md:w-20 h-auto"
                            />
                        </div>
                    )}
                </div>
            </Container>
        </div>
    )
}
