'use client';

import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { Params } from '@/lib/types/shared/page.types';
import { convertMarkdownLinks } from '@/lib/utils';
import { Typography } from '@mui/material';
import React from 'react'
import HeadingText from '@/components/shared/HeadingText';

type Props = {
	params: Params;
	title: string;
	text: string;
	titleVariant?: any;
	textVariant?: any;
	convertTextToLink?: boolean;
	delayMultiplier?: number;
}

export default function GridTextItem({ params, title, text, titleVariant = "h3", textVariant = 'body1', convertTextToLink = false, delayMultiplier = 0}: Props) {
	return (
		<>
			<div>
				<MoveFadeInAnimation delay={200 * delayMultiplier} direction="right" distance="xl" amount={0.5}>
					<HeadingText className='mb-2' variant={titleVariant}>
						{title}
					</HeadingText>
				</MoveFadeInAnimation>

				<Typography className='text-start normal-case max-w-screen-md' variant={textVariant}>
					{convertTextToLink ?
						<span className='text-start normal-case max-w-screen-md' dangerouslySetInnerHTML={{
							__html: convertMarkdownLinks(text!, params.locale)
						}}></span>
					: text}
				</Typography>
			</div>
		</>
	)
}
