'use client'

import { MoveFadeInAnimation } from "@/lib/hooks/use-animations"
import { route } from "@/lib/navigationFunctions"
import { Params } from "@/lib/types/shared/page.types"
import { Container, Typography } from "@mui/material"
import ButtonOne from "@/components/shared/ButtonOne"
import Image from "@/components/shared/Image"
import { useTranslations } from "next-intl"
import Link from "next/link"
import useEncryptedLocalStorage from "@/lib/hooks/use-encrypted-local-storage"
import { useEffect, useState } from "react"
import { dispatchQuoteModalOpen } from "@/lib/helpers/forms/QuoteFormHelper"
import { ContactSection } from "@/components/shared"
import HeadingText from "@/components/shared/HeadingText"

type Props = {
    params: Params,

    subject? : string,
}

type LocalStorageObject = {
    selectedABVariant?: string | null,
}

export default function AbContactSection({ params, subject = '' }: Props) {
    const t = useTranslations('shared.components.AbContactSection');
    
    let aTitle = ''
    let aText = ''
    let bTitle = ''
    let bText = ''

    if (!aTitle) aTitle = t('default_a_title');
    if (!subject) subject = t('default_a_subject');
    if (!aText) aText = t('a_content', { "subject": subject });
    
    if (!bTitle) bTitle = t('default_b_title');
    if (!subject) subject = t('default_b_subject');
    if(!bText) bText = t('b_content', { "subject": subject });

    const generateABVariant = () => {
        const abTesting = Math.random() < 0.5 ? 'A' : 'B';
        // const abTesting = 'B'
        return abTesting;
    }

    const defaultLocalStorageObject: LocalStorageObject = {
        selectedABVariant: null,
    }

    const [localStorageObject, setLocalStorageObject, isFirstLoad, usingInitialValue, reset] = useEncryptedLocalStorage('contactSectionAB', defaultLocalStorageObject);
    const [localStorageObjectLoaded, setLocalStorageObjectLoaded] = useState(false);
    const [selectedABVariant, setSelectedABVariant] = useState(localStorageObject.selectedABVariant ?? '');

    useEffect(() => {
        setTimeout(() => {
            setLocalStorageObjectLoaded(true);
        }, 50);
    }, [isFirstLoad]);

    useEffect(() => {
        if (localStorageObjectLoaded) {
            if (usingInitialValue) {
                const abVariant = generateABVariant();
                setLocalStorageObject({
                    selectedABVariant: abVariant,
                }, 'initFunction');
            } else {
                if (localStorageObject.selectedABVariant !== 'A' && localStorageObject.selectedABVariant !== 'B') {
                    setLocalStorageObject({
                        selectedABVariant: 'A',
                    }, 'fallbackFunction');
                }
                setSelectedABVariant((localStorageObject.selectedABVariant !== 'A' && localStorageObject.selectedABVariant !== 'B') ? '' : localStorageObject.selectedABVariant);
            }
        }
    }, [localStorageObjectLoaded]);

    useEffect(() => {
        if (localStorageObjectLoaded) {
            setSelectedABVariant((
                localStorageObject.selectedABVariant !== 'A' &&
                localStorageObject.selectedABVariant !== 'B')
                ? ''
                : localStorageObject.selectedABVariant
            );
        }
    }, [localStorageObject]);

    async function handleABButtonClick (buttonId: string) {
        const ABVariant = (localStorageObject.selectedABVariant !== 'A' && localStorageObject.selectedABVariant !== 'B') ? '': localStorageObject.selectedABVariant
        
        fetch('/api/ablogger', {
            method: 'POST',
            headers: {
                'Custom-WB-Token': process.env.NEXT_APP_API_BEARER ?? '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                clickedVariant: ABVariant,
                component: 'abContactSection',
            })
        }).then(data => {
            return data.json();
        }).catch(error => {
            return error;
        });
    }

    const handleCalendlyButton = () => {
        handleABButtonClick('calendly');
        if(typeof window !== 'undefined') {
            window.location.href = route("/contact", params.locale, "?calendly=true");
        }
    }
    const handleQuoteButton = () => {
        handleABButtonClick('quoteFormModal');

        dispatchQuoteModalOpen();
    }

    return (
        <>
            {/* <h1 className="bold text-xl">{selectedABVariant ?? 'Not Set!'}</h1> */}

            {selectedABVariant == 'A' && (
                <>
                    <ContactSection title={aTitle} text={aText} onButtonClick={handleABButtonClick} />
                </>
            )}
            {selectedABVariant == 'B' && (
                <>
                    <section className="my-16 py-16 scroll-mt-16 relative">
                        <div className="w-full h-full bg-gradient-to-r from-primary-gray-950 to-50% via-green-700 to-primary-gray-950 absolute top-1/2 -translate-y-1/2 left-0 z-0" />
                        <Container className='relative z-1'>
                            <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant='h3' enableMaxWidth={false} className='mb-4 w-full text-center'>{ bTitle }</HeadingText>
                            </MoveFadeInAnimation>

                            {bText && (
                                <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                                    <Typography className={`text-center normal-case text-base md:text-xl max-w-screen-md mx-auto`} variant='body1'>
                                        { bText }
                                    </Typography>
                                </MoveFadeInAnimation>
                            )}

                            <div className="w-fit mx-auto mt-5 flex flex-wrap gap-3">
                                <MoveFadeInAnimation delay={400} direction="right" distance="xl" amount={0.5} extraclasses="mx-auto">
                                    {/* <ButtonOne variant="primary" text={t('buttons.0.text')} link={route("/contact", params.locale, "?calendly=true")} hoverEffect={true} /> */}
                                    <ButtonOne variant="primary" text={t('buttons.0.text')} type="button" onclick={() => { handleCalendlyButton() }} hoverEffect={true} />
                                </MoveFadeInAnimation>
                                <MoveFadeInAnimation delay={400} direction="right" distance="xl" amount={0.5} extraclasses="mx-auto">
                                    <ButtonOne variant="primary" text={t('buttons.1.text')} type="button" onclick={() => { handleQuoteButton() }} hoverEffect={true} />
                                </MoveFadeInAnimation>
                            </div>

                            <div className='flex items-center gap-5 mb-10 md:mb-0 w-fit mt-16 mx-auto'>
                                <Image
                                    loading="lazy"
                                    src="TeamMembers/stephan_a11ipm"
                                    alt='Stephan Striekwold'
                                    width={80}
                                    height={80}
                                    className='w-20 h-20 bg-white-accent-50 rounded-full'
                                />
                                <div>
                                    <Typography className="text-2xl font-bold capitalize mb-0 leading-4" variant="body1">
                                        Stephan Striekwold
                                    </Typography>
                                    <Typography variant='body1'>
                                        {t("contact_info.position")}
                                    </Typography>

                                    <div className="flex gap-3 mt-3">
                                        <div>
                                            <Typography className='font-semibold leading-3' variant='body1'>
                                                {t("contact_info.phone.label")}
                                            </Typography>
                                            <Link href='tel:+31(0)342 22 64 86' className='underline font-extralight'>
                                                {t("contact_info.phone.value")}
                                            </Link>
                                        </div>
                                        <div>
                                            <Typography className='font-semibold leading-3' variant='body1'>
                                                {t("contact_info.mail.label")}
                                            </Typography>
                                            <Link href='mailto:stephan@webbeukers.nl' className='underline font-extralight'>
                                                {t("contact_info.mail.value")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                </>
            )}

        </>
    )
}
